import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

export const AnnouncementBanner = (props) => {
  const keyName = 'announcement-hide';
  const keyValue = 'mhi-ewg';

  const [showAlert, setShowAlert] = useState(() => sessionStorage.getItem(keyName) !== keyValue);
  const [enableAlert] = useState(() => props.enableAlert !== false);

  const handleAlertClose = () => {
    sessionStorage.setItem(keyName, keyValue);
    setShowAlert(false);
  };

  if (showAlert && enableAlert)
    return (
      <div className='ahrq-alert'>
        <Container>
          <div>
            AHRQ invites public comment on the development of diagnostic excellence measures -
            submit feedback by February 13, 2025: <br />
            <a
              href='https://www.federalregister.gov/documents/2024/12/12/2024-29134/request-for-information-regarding-diagnostic-excellence-measurement'
              aria-label='Announcements'
              rel='noreferrer'
              target='_blank'
            >
              Learn more and respond to the RFI
            </a>
            {'.'}
          </div>
          <CloseButton onClick={() => handleAlertClose()} aria-label='warning close' />
        </Container>
      </div>
    );

  return null;
};
